import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <section className='footer'>
        <span>Developed by João Bogo | 2023     
        </span>
    </section>
  )
}

export default Footer